@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  @apply font-body;
  background-color: #eaeaea;
}

input[type="checkbox"]:hover+div {
  @apply border-primary-blue;
}

input:checked+div svg {
  @apply block;
}

.toggle-bg:after {
  content: '';
  @apply h-5 w-5 top-0.5 left-0.5 shadow-sm absolute transition rounded-full bg-white border border-light-gray;
}

input:checked+.toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input[type="checkbox"]:checked+.toggle-bg {
  @apply bg-primary-blue border-primary-blue;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

.Toastify__toast-container--bottom-center {
  padding: 0;
  width: 80%;
  border: none;
  box-shadow: none;
  margin-bottom: 5px;
  background: transparent;
}

.Toastify__toast--default {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: transparent;
}

.Toastify__progress-bar--default {
  margin: 0 20px;
  margin-bottom: 18px;
  background: #555555;
}